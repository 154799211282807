<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content" style="background-color: #393939">
                <div class="main_big_img">
                    <div class="player player1">
                        <img src="../../assets/images/bg/oneplay/main_page_img_player1.png" alt="">
                    </div>
                    <div class="player player2">
                        <img src="../../assets/images/bg/oneplay/main_page_img_player2.png" alt="">
                    </div>
                </div>
                <div class="middle_section">
                    <div class="m_section_left">
                        <div class="middle_title">
                            <i class="fa fa-chevron-right"></i> 추천 아이템
                        </div>
                        <div class="m_section_content">
                            <div class="it" v-for="(item,index) in itemList" :key="index">
                                <router-link to="/item_shop" tag="div" class="it_image" style="cursor: pointer">
                                    <!--<img :src="item.imageUrl">-->
                                    <img :src="require(`@/assets/images/item/oneplay/${item.imageUrl}`)">
                                </router-link>
                                <div class="it_title">
                                    <div class="it_t1">{{item.name}}</div>
                                    <div class="it_t2">{{item.price|comma}} 코인</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="m_section_right">
                        <div class="middle_title">
                            <i class="fa fa-chevron-right"></i> 공지사항
                        </div>
                        <ul class="notice_list">
                            <li v-for="(notice,index) in noticeList" :key="index" class="">
                                <router-link tag="span" :to="{name:'notice',query:{id:notice.id}}">{{notice.title}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>
        <foot-comp></foot-comp>

    </div>


</template>
<style scoped>
    @import url("../../assets/css/main.css");

</style>
<script>
    import RightBarComp from "../../components/RightBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import {swiper, swiperSlide} from "vue-awesome-swiper"
    import "swiper/dist/css/swiper.css";
    import FootComp from "../../components/FootComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {getItemList, getNoticeList} from "../../network/userRequest";

    export default {
        name: "Main",
        components: {
            SportsBetCartComp,
            RightBarBannerComp,
            LeftBarComp, SportsLeftBarComp, FootComp, TopbarComp, RightBarComp, swiper, swiperSlide
        },
        data() {
            return {
                noticeList:[],
                noticePageSize:8,
                inplayList: [],
                itemList: [],
                wsData: null,
                websocket: null,
                sportsConst,
                inplayConst,
                search: {},
                //swiper 初始化
                swiperOption: {
                    loop: true,
                    autoplay: {
                        delay: 3000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    // 显示分页
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true //允许分页点击跳转
                    },
                    // 设置点击箭头
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                }
            }
        },
        methods: {
            initItemList() {
                getItemList(this.search, 1, 6).then(res => {
                    if (res.data.success) {
                        this.itemList = res.data.data
                    }
                })
            },
            initNoticeList(){
                /*공지사항추출*/
                getNoticeList(1,5).then(res=>{
                    if (res.data.success) {
                        this.noticeList = res.data.data
                    }
                })
            },
            onSwiper() {

            },
            onSlideChange() {

            },

        },
        created() {
            this.initItemList();
            this.initNoticeList();
        }
    }
</script>

